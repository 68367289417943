<template>
  <section class="section container p-t-lg">
    <h1>CURSOS ASSBAN ONLINE</h1>
    <hr>
    <!-- <div class="custom">
      <h2>CPA 10 - ANBIMA</h2>
      <div class="columns">
        <div class="column is-2">
          <h4>Detalhes</h4>
          <div class="is-flex">
            <img src="https://weboverhauls.github.io/demos/svg/checkmark.svg" alt="Check">
            <span>20 horas</span>
          </div>
          <div class="is-flex">
            <img src="https://weboverhauls.github.io/demos/svg/checkmark.svg" alt="Check">
            <span>9 Módulos</span>
          </div>
          <div class="is-flex">
            <img src="https://weboverhauls.github.io/demos/svg/checkmark.svg" alt="Check">
            <span>8 Vídeos</span>
          </div>
        </div>
        <div class="column is-2">
          <h4>&nbsp;</h4>
          <div class="is-flex">
            <img src="https://weboverhauls.github.io/demos/svg/checkmark.svg" alt="Check">
            <span>80 Exercícios</span>
          </div>
          <div class="is-flex">
            <img src="https://weboverhauls.github.io/demos/svg/checkmark.svg" alt="Check">
            <span>1 Apostilas</span>
          </div>
          <div class="is-flex">
            <img src="https://weboverhauls.github.io/demos/svg/checkmark.svg" alt="Check">
            <span>Nível Básico</span>
          </div>
        </div>
        <div class="column is-5">
          <h4>Público alvo</h4>
          <p>Profissionais das Instituições Financeiras que desempenham atividades de comercialização e distribuição de produtos de investimento e estão alocados em agências bancárias que têm contato direto com clientes e usuários de produtos e serviços bancários. É também direcionado às pessoas que desejam atuar como profissionais no mercado financeiro, inclusive cooperativas de crédito.</p>
        </div>
        <div class="column saiba-mais">
          <img src="http://www.assbandfonline.com.br/static/uniopen/img/icone-info.png" alt="Icone">
          <button class="button is-text">SAIBA MAIS</button>
        </div>
      </div>
    </div>-->

    <div class="card m-b-lg" v-for="course in courses" :key="course._id">
      <header class="card-header">
        <p class="card-header-title">{{course.name}}</p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="columns">
            <div class="column">
              <h4>Detalhes</h4>
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <b-icon custom-class="icon" icon="check" size="is-small"></b-icon>
                      <span class="span">{{course.hourlyLoad}} Horas</span>
                    </td>
                    <td>
                      <b-icon custom-class="icon" icon="check" size="is-small"></b-icon>
                      <span class="span">{{course.lessonsAmount}} Exercícios</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-icon custom-class="icon" icon="check" size="is-small"></b-icon>
                      <span class="span">{{course.modulesAmount}} Módulos</span>
                    </td>
                    <td>
                      <b-icon custom-class="icon" icon="check" size="is-small"></b-icon>
                      <span class="span">{{course.docs ? course.docs.length : 0}} Apostilas</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-icon custom-class="icon" icon="check" size="is-small"></b-icon>
                      <span class="span">{{course.videosAmount}} Vídeos</span>
                    </td>
                    <td>
                      <b-icon custom-class="icon" icon="check" size="is-small"></b-icon>
                      <span class="span">Nível {{course.level}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="column">
              <h4>Público alvo</h4>
              <p>{{course.targetAudience}}</p>
            </div>
          </div>
        </div>
      </div>
      <footer class="card-footer">
        <router-link :to="{name: 'Detalhes do curso', params:{ name: course._id }}" class="card-footer-item">SAIBA MAIS</router-link>
      </footer>
    </div>
  </section>
</template>

<script>
import Axios from "axios";

export default {
  data() {
    return {
      courses: []
    };
  },
  methods: {
    getCourse() {
      Axios.get(`/v1/courses/info?active=true`)
        .then(data => {
          if (data.data) {
            this.courses = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    }
  },
  mounted() {
    this.getCourse();
  }
};
</script>

<style scoped lang="scss">
.custom {
  padding: 1rem;
  position: relative;
  width: 100%;
  background-color: rgb(248, 246, 246);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

  h2 {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    position: absolute;
    top: 1rem;
  }

  h4 {
    font-size: 1.3rem;
    margin-bottom: 0.7rem;
  }

  .columns {
    .saiba-mais {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dbdbdb;
      img {
        margin-top: -3rem;
        width: 10rem;
        height: 10rem;
      }
    }

    .column {
      padding-top: 4rem;
      div {
        align-items: center;
        height: 2rem;

        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
tr {
  td {
    position: relative;

    .icon {
      position: absolute;
      top: 12px;
    }

    .span {
      margin-left: 23px;
    }
  }
}
</style>
